import React, { useEffect, useState } from 'react';
import './style.css';
import { useNavigate, useParams } from 'react-router-dom';
import Field from '../../../_common/field';
import AddressLocationFieldJS from '../../../components/AddressLocationField/AddressLocationFieldJS';
import AddressLocationField from '../../../components/AddressLocationField/AddressLocationField';
import { connect } from 'react-redux';
import { GeoService } from '../../../_services/geo.service';
import { apiService } from '../../../_services/api.service';
import getImageURL from '../../../_helpers/getImageURL';
import currencyDisplayHelper from '../../../_helpers/currencyDisplayHelper';

const RideCategory = ({ infosetting, match, dispatch, history }) => {
    const { destination_coords, destination_address, pickup_coords, pickup_address } = useParams();
    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [distance, setDistance] = useState(0);
    const [categories, setCategories] = useState([]);

    const [bookingPlaced, setBookingPlaced] = useState(false);

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        calcualateDistance();
    }, [destination_coords, pickup_coords]);

    useEffect(() => {
        if (distance > 0 && categories.length > 0) {
            let categoryList = JSON.parse(JSON.stringify(categories));
            for (let i = 0; i < categoryList.length; i++) {
                let category = categoryList[i];
                category["rate"] = category.ratePerKM * distance;
            }
            setCategories(categoryList)
        }
    }, [distance, categories]);

    const getCategories = () => {
        apiService.getAll("categories").then(setCategories);
    }

    const calcualateDistance = () => {
        const pickupCoords = GeoService.extractCoordsfromURL(pickup_coords);
        const destinationCoords = GeoService.extractCoordsfromURL(destination_coords);
        const distanceVal = GeoService.manualDistance(pickupCoords.lat, pickupCoords.lng,
            destinationCoords.lat, destinationCoords.lng);
        setDistance(distanceVal);
    }

    return bookingPlaced ?
        (
            <div className='section'>
                <div className='banner-bg center'>
                    <span className='title-white'>Success!</span>
                </div>
                <div className='padder center column booking-success' >
                    <i className='fa fa-circle-check success-icon'></i>
                    <h3>Ride Booking Placed</h3>
                    <p className='text-center'>Your ride request is in the marketplace. A rider will accept soon,<br /> and you'll receive a notification.</p>
                    <div className="submission-container center mt-50">
                        <button class="btn btn-primary btn-block" onClick={() => setBookingPlaced(true)}>View Market Place</button>
                    </div>
                </div>
            </div>
        )
        : (
            <div className='section'>
                <div className='banner-bg center'>
                    <span className='title-white'>Select Category</span>
                </div>
                <div className='padder center column' >
                    <div className='s-categories'>
                        {categories.map((category, key) => <div className={`s-category ${key === selectedCategory ? 's-category-selected' : ''}`} onClick={() => setSelectedCategory(key)}>
                            <div className='s-category-inner'>
                                <img src={getImageURL(category.image)} />
                                <h3>{category.name}</h3>
                                <p>{category.description}</p>
                            </div>
                            <div className='c-category-bottom'>
                                <span>
                                    Distance {distance} km
                                </span>
                                <span>
                                    Price {currencyDisplayHelper(category.rate)}
                                </span>
                            </div>
                        </div>)}
                    </div>

                    <div className="submission-container center mt-50">
                        <button class="btn btn-primary btn-block" onClick={() => setBookingPlaced(true)}>Confirm</button>
                    </div>

                </div>
            </div>
        );
};


function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn, ...state };
}

export default connect(mapStateToProps)(RideCategory);