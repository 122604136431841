import React, { useEffect, useState } from 'react';
import './style.css';
import { useNavigate, useParams } from 'react-router-dom';
import Field from '../../../_common/field';
import AddressLocationFieldJS from '../../../components/AddressLocationField/AddressLocationFieldJS';
import AddressLocationField from '../../../components/AddressLocationField/AddressLocationField';
import { connect } from 'react-redux';

const Destination = ({ infosetting, match, dispatch, history }) => {
    const [step, setStep] = useState("select-location");
    const [locationPermissionState, setLocationPermissionState] = useState(null);
    const [address, setAddress] = useState("");
    const [location, setlocation] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        handlePermission();
    }, []);

    const handlePermission = () => {
        if (navigator.permissions && navigator.permissions.query) {
            navigator.permissions.query({ name: 'geolocation' })
                .then(function (result) {
                    if (result.state === "granted") {
                        allowLocationService();
                    }
                    setLocationPermissionState(result.state);

                    result.onchange = function () {
                        setLocationPermissionState(result.state);
                    }
                })
        } else if (navigator.geolocation) {
            allowLocationService();
        }
    }

    const allowLocationService = () => {
        var options = {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 1000
        };
        navigator.geolocation.getCurrentPosition(({ coords }) => {
            setLocationPermissionState("granted");
            setlocation({ type: "Point", coordinates: [coords.latitude, coords.longitude] });
        }, (err) => {
            setLocationPermissionState("prompt");
        }, options);

    }

    const handleSelectLocationButtonClick = (clear = false) => {
        navigate(`/request/select-location/${location.coordinates}/${address}`)

    }

    return (
        <div className='section'>
            <div className='banner-bg center'>
                <span className='title-white'>Book a Ride!</span>
            </div>
            <div className='padder'>
                {locationPermissionState && step === "select-location" &&
                    (
                        (locationPermissionState === "prompt" || locationPermissionState === "denied") ? <div
                            className="sl-prompt-container">
                            <div className="sl-heading-container center-content">
                                <span className="sl-heading">Location Services</span>
                                <hr />
                            </div>
                            <div className="center column text-center">
                                <i aria-hidden="true" class="fa fa-street-view success-icon mt-30"></i>

                                <div className="mt-20">
                                    <span className="primary-text font-weight-bold">{infosetting.title}</span>
                                    <span className="ml-5">uses location services in order to determine proximity of listed <br />businesses and only while you are using the application.</span>
                                </div>

                                <button class="btn btn-primary mt-20" onClick={allowLocationService}>Enable location services</button>
                            </div>
                        </div>
                            :
                            location && <div className="">
                                <div className="column">
                                    <span className="sl-heading primary-text">Select Destination</span>
                                    <span className="sl-sub-heading mt-10">Please move the map to the exact location of delivery</span>
                                    <hr />
                                </div>
                                <div className="sl-body-content">
                                    <div className="sl-address">
                                        <Field>
                                            <AddressLocationField
                                                name="addressVal"
                                                placeholder="or move the map"
                                                value={address}
                                                location={location}
                                                mapHeight={400}
                                                onChange={({ addressVal, addressValLocation }) => {
                                                    setAddress(addressVal);

                                                    if (addressValLocation) {
                                                        setlocation(addressValLocation)
                                                    }
                                                }}

                                            />
                                        </Field>
                                    </div>

                                </div>
                                <div className="submission-container center">
                                    <button class="btn btn-primary btn-block" onClick={handleSelectLocationButtonClick} disabled={!location}>Next</button>
                                </div>
                            </div>
                    )
                }
            </div>
        </div>
    );
};


function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn, ...state };
}

export default connect(mapStateToProps)(Destination);