export const GeoService = {
    manualDistance: function (lat1, lon1, lat2, lon2) {
        var p = 0.017453292519943295;    // Math.PI / 180
        var c = Math.cos;
        var a = 0.5 - c((lat2 - lat1) * p) / 2 +
            c(lat1 * p) * c(lat2 * p) *
            (1 - c((lon2 - lon1) * p)) / 2;
        var d = 12742 * Math.asin(Math.sqrt(a));
        let ud = d + (d / 2);
        return parseFloat(parseFloat(ud + "").toFixed(2));
    },
    extractCoordsfromURL: (coords) => {
        let coordsArr = coords.split(',');
        let lat = parseFloat(coordsArr[0]);
        let lng = parseFloat(coordsArr[1]);
        return { lat, lng };

    }
}