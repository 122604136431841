import React, { useEffect, useState } from 'react';
import FieldsGenerator from '../../../components/FieldsGenerator';
import { apiService } from '../../../_services/api.service';
import generateValidation from '../../../_helpers/generateValidation';
import fillInObject from '../../../_helpers/fillInObject';
import { validate } from 'validate.js';
import { toast } from 'react-toastify';

const RiderInfo = ({ data, onChangeForm }) => {
    const [selectedProfileImage, setProfileImage] = useState(null);
    const [bluePrint, setBluePrint] = useState(null);
    const [formObj, setFormObj] = useState(null);
    const [validationRule, setValidationRule] = useState(null);
    const [errors, setErrors] = useState({});
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        initForm();
    }, [data]);

    useEffect(() => {
        if (formObj) {
            onChangeForm && onChangeForm(formObj)
        }
    }, [formObj]);

    const initForm = async () => {
        let modelType = await apiService.type("riders");
        modelType.bluePrint["user"]["hidden"] = true;
        setBluePrint(modelType.bluePrint);

        let { mFormObj, mValidationRule } = generateValidation(modelType.bluePrint);
        setFormObj(data ? data : mFormObj); setValidationRule(mValidationRule); setLoading(false);

    }

    const handleChange = ({ target }) => {
        const { name, value } = target;
        handleChageCustom(name, value);
    }

    const handleChageCustom = (name, value) => {
        const errors = validate({ [name]: value }, { [name]: validationRule[name] });
        let obj = fillInObject(Object.assign({}, formObj), name, value);
        setFormObj(obj);
        setErrors(Object.assign({}, errors,
            errors ? errors : { [name]: undefined }
        ));
    }

    const handleSubmit = (e) => {
        e && e.preventDefault();
        const errors = validate(formObj, validationRule);
        if (errors) {
            toast.error("Please enter all required fields");
            setErrors(errors);
            return;
        }

        setLoading(true);
    }

    return (
        <form className="form-container mt-20 ml-20" onSubmit={handleSubmit}>
            <h3>Additional Information</h3>
            <FieldsGenerator
                errors={errors}
                formObj={formObj}
                handleChageCustom={handleChageCustom}
                handleChange={handleChange}
                isLoading={isLoading}
                bluePrint={bluePrint}
                fieldClass="col-sm-12"
                key={0}
            />

            {/* <button
                type="submit"
                className="btn btn-primary btn-block mt-20"
                onClick={() => handleSubmit()}
                disabled={isLoading}
            >
                {isLoading ? "Please Wait..." : "Save Rider Info"}
            </button> */}
        </form>
    );
};

export default RiderInfo;