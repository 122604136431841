import React, { useEffect, useState } from 'react';
import config from '../../_config';
import Axios from 'axios';
import LocationSearchInput from '../LocationSearchInput';
import './AddressLocationField.css'
import './style.css';

const AddressLocationField = ({ name, placeholder, value, geoFence, geoFenceBluePrint, viewable, location, onChange, mapHeight, markerImage, setMapRefCallback, marker_coords }) => {
    const mapId = `map-${name}`;
    const [mapRef, setMapRef] = useState(null);
    const [marked, setMarked] = useState(false);
    const [marking, setMarking] = useState(false);
    const [polygonRef, setPolygonRef] = useState(null);

    useEffect(() => {
        let mapElement = document.getElementById(mapId);

        let latLngCenter = myLatLng();
        const map = new window.google.maps.Map(
            mapElement,
            {
                zoom: config.mapZoom,
                center: latLngCenter,
                streetViewControl: false,
                fullscreenControl: false,
                styles: [
                    {
                        "featureType": "poi",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                ]

            },


        );

        // const marker = new window.google.maps.Marker({
        //     position: {
        //         lat: 31.5997411,
        //         lng: 74.3689994
        //     },
        //     title: 'Destination',
        // });

        // marker.setMap(map);

        if (marker_coords) {
            const marker = new window.google.maps.Marker({
                position: {
                    lat: parseFloat(marker_coords.split(',')[0]),
                    lng: parseFloat(marker_coords.split(',')[1])
                },
                title: 'Uluru',
            });
            marker.setMap(map);
        }


        if (!viewable) {
            map.addListener("dragend", () => {
                // marker.setPosition({ lat: map.getCenter().lat(), lng: map.getCenter().lng() });
                geoCoding(name, null, map.getCenter()).then(g => {
                    onChange && onChange(g);
                })
            });

            map.addListener("click", ({ latLng }) => {
                const { marking, marked } = this.state;
                if (geoFenceBluePrint && marking) {
                    handleRegionPointSelect(latLng, map)
                }
            });


            map.addListener("zoom_changed", () => {
                geoCoding(name, null, map.getCenter()).then(g => {
                    onChange && onChange(g);
                })
            })
        }

        setMapRef(map);
        if (setMapRefCallback) {
            setMapRefCallback(map);
        }
    }, []);

    useEffect(() => {
        if (mapRef) {
            mapRef.panTo(new window.google.maps.LatLng(location.coordinates[0], location.coordinates[1]));
        }
    }, [location]);

    const myLatLng = () => {
        return location && location.coordinates ? { lat: location.coordinates[0], lng: location.coordinates[1] } : { lat: config.defaultLatLng.lat, lng: config.defaultLatLng.lng };
    }

    const geoCoding = (name, address, latLng = null) => {
        let params = latLng ? { key: config.googleMapAPIKey, latlng: latLng.lat() + "," + latLng.lng() } : { key: config.googleMapAPIKey, address }
        return Axios.get("https://maps.googleapis.com/maps/api/geocode/json", { params }).then(response => {
            let result = response.data.results[0];
            if (result) {
                return {
                    addressVal: result.formatted_address,
                    addressValLocation: { type: "Point", coordinates: latLng ? [latLng.lat(), latLng.lng()] : [result.geometry.location.lat, result.geometry.location.lng] }
                }
            } else {
                throw Object.assign({
                    message: "No Result Found"
                })
            }
        })
    }

    const handleRegionPointSelect = (latLng, map) => {
        let geoFenceVal = JSON.parse(JSON.stringify(geoFence));
        if (!geoFenceVal) {
            geoFenceVal = { type: "Polygon", coordinates: [[]] }
        }
        geoFenceVal.coordinates[0].push([latLng.lat(), latLng.lng()]);


        setPolygonPath(geoFenceVal, map)
        onChange({
            [name + "GeoFence"]: geoFenceVal
        }, true)

    }

    const setPolygonPath = (geoFence, map) => {
        if (geoFence && geoFence.coordinates) {
            function getPolygonPaths(crds) {
                let tPath = [];
                crds.forEach(c => {
                    tPath.push({ lat: c[0], lng: c[1] })
                });
                return tPath;
            }

            const polygonConfig = new window.google.maps.Polygon({
                paths: getPolygonPaths(geoFence.coordinates[0]),
                strokeColor: "#0CA8DB",
                strokeOpacity: 0.8,
                strokeWeight: 1,
                fillColor: "#0CA8DB",
                fillOpacity: 0.35,
            });
            if (polygonRef) {
                polygonRef.setPaths(getPolygonPaths(geoFence.coordinates[0]));
                setPolygonRef(polygonRef)
            } else {
                polygonConfig.setMap(map);
                setPolygonRef(polygonConfig);
            }
        }

    }

    return (
        <React.Fragment>
            {
                !viewable && <LocationSearchInput
                    searchAddress={{ address: value, latLng: myLatLng() }}
                    label={placeholder}
                    onChange={(g) => {
                        onChange({ addressVal: g.address, addressValLocation: { type: "Point", coordinates: [g.latLng.lat, g.latLng.lng] } })
                    }}
                />
            }
            <img className="marker-image" src={markerImage ? markerImage : "/assets/icons8-marker.png"} />
            <React.Fragment>
                <div id={mapId} style={{ height: mapHeight ? `${mapHeight}px` : `300px`, width: `100%`, marginBottom: `30px`, zIndex: 1 }}></div>
            </React.Fragment>


        </React.Fragment >
    );
};

export default AddressLocationField;