export const authenticationConstants = {
    LOGIN_REQUEST: 'USER_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USER_LOGIN_FAILURE',

    GET_CURRENT_REQUEST: 'USER_GET_CURRENT_REQUEST',
    GET_CURRENT_SUCCESS: 'USER_GET_CURRENT_SUCCESS',
    GET_CURRENT_FAILURE: 'USER_GET_CURRENT_FAILURE',
    
    LOGOUT: 'USER_LOGOUT'
};
