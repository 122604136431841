import { infosettingConstants } from "../_constants/infosetting.constants";
import { apiService } from "../_services/api.service";
// import { history } from "../_helpers";

export const infosettingActions = {
    fetch: (() => {
        return dispatch => {
            apiService.getAll('infosettings').then(res => {
                dispatch({ type: infosettingConstants.SET_INFOSETTINGS, data: res })
            })
        }
    })
};
