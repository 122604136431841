import React, { Fragment, useEffect, useState } from 'react';
import './style.css';
import { connect } from 'react-redux';
import getImageURL from '../../../_helpers/getImageURL';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { apiService } from '../../../_services/api.service';

const Home = ({ infosetting }) => {
    useEffect(() => {
        if (infosetting) {
            document.title = `${infosetting.title}`;
        }
    }, [infosetting]);


    return (
        <div className='section'>
            <div className='home-banner'>
                <div className='home-banner-heading-section'>
                    <h1 className='home-banner-title'>Nigeria's Leading On-demand Ride-Hailing and Logistics Service.</h1>
                    <Link className='btn btn-secondary' to="/request/destination">
                        Book Now!
                    </Link>
                </div>
                <div className='home-banner-mockup-section'>
                    <img src='/assets/delivery-bike-mockup.png' />
                </div>
            </div>
            <div className='padder'>
                <h1>Homehunt Delivery, is an all-in-one app for transportation, logistics, <br />and cash-on-delivery payments</h1>
                <p className='sub-heading'>Our services help move people, parcels and payments efficiently in a safe, reliable and inexpensive way to customers offering the lowest fares in town. Customers always have a great experience with us is our priority.</p>
            </div>

            <div className='padder bg-grey'>
                <p className='text-grey'>Why Homehunt Delivery</p>
                <h1 className='title title-padding'>Reasons to choose us</h1>

                <div className='home-features'>
                    <div className='home-feature'>
                        <img className='home-feature-icon' src='/assets/fast.png' />
                        <span className='home-feature-title'>Fast</span>
                        <p className='home-feature-caption'>Experience the fastest travel times and reach your destination quicker than ever.</p>
                    </div>
                    <div className='home-feature'>
                        <img className='home-feature-icon' src='/assets/safe.png' />
                        <span className='home-feature-title'>Safe</span>
                        <p className='home-feature-caption'>Our driving partners are licensed and GPS tracked to ensure safety for you.</p>
                    </div>
                    <div className='home-feature'>
                        <img className='home-feature-icon' src='/assets/reliable.png' />
                        <span className='home-feature-title'>Reliable</span>
                        <p className='home-feature-caption'>We are always there for you at every step of the way through our customer support centre.</p>
                    </div>
                </div>
            </div>

            <div className='padder careers-bg home-careers center column'>
                <h1 className='home-banner-title'>Looking for Opportunities?</h1>
                <h2 className='text-white home-careers-caption'>Discover Homehunt Delivery Careers</h2>
                <div className='btn btn-secondary' to="/marketplace">
                    View Market Place
                </div>
            </div>
        </div>
    );
};


function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn, ...state };
}

export default connect(mapStateToProps)(Home);