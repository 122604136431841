import React, { useEffect, useState } from 'react';
import './style.css';
import { connect } from 'react-redux';
import { authenticationService } from '../../../_services/authentication.service';
import { onChangeHandler } from '../../../_helpers/imageUploadValidator';
import { toast } from 'react-toastify';
import { validate } from 'validate.js';
import { APILoader } from '../../../components/APILoader';
import FieldsGenerator from '../../../components/FieldsGenerator';
import { authenticationActions } from '../../../_actions/authentication.actions';
import fillInObject from '../../../_helpers/fillInObject';
import generateValidation from '../../../_helpers/generateValidation';
import getImageURL from '../../../_helpers/getImageURL';
import { compressString } from '../../../_helpers/compressString';
import { useOutletContext } from 'react-router-dom';
import { apiService } from '../../../_services/api.service';
import RiderInfo from './RiderInfo';

const Profile = ({ dispatch, infosetting, user }) => {
    const [selectedProfileImage, setProfileImage] = useState(null);
    const [bluePrint, setBluePrint] = useState({
        'profileImage': {
            type: String,
            label: "Profile Image",
            placeholder: "Profile Image",
            icon: "ti-user",
            iconColor: "color-primary",
            dataType: "String",
            default: "",
            required: true,
            pattern: "[a-zA-Z0-9]{4,15}",
            hidden: true
        },
        'email': {
            type: String,
            label: "Email",
            placeholder: "Enter Email",
            icon: "ti-email",
            iconColor: "color-primary",
            dataType: "String",
            default: "",
            required: true,
            email: true,
            disabled: true
        },
        'name': {
            type: String,
            label: "Name",
            placeholder: "Enter Name",
            icon: "ti-user",
            iconColor: "color-primary",
            dataType: "String",
            default: "",
            required: true,
            pattern: "[a-zA-Z0-9 ]{4,25}"
        },
        // 'phoneNo': {
        //     type: String,
        //     label: "Phone Number",
        //     placeholder: "Enter Phone Number",
        //     icon: "fas fa-phone",
        //     iconColor: "color-primary",
        //     dataType: "String",
        //     default: "",
        //     required: true,
        //     minLength: 7
        // },

    });
    const [formObj, setFormObj] = useState(null);
    const [validationRule, setValidationRule] = useState(null);
    const [errors, setErrors] = useState({});
    const [formError, setFormError] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const isMobile = window.matchMedia('screen and (max-width: 768px)').matches ? true : false;
    const [riderInfo] = useOutletContext();
    const [riderInfoForm, setRiderInfoForm] = useState(null);

    useEffect(() => {
        let { mFormObj, mValidationRule } = generateValidation(bluePrint);
        setFormObj(mFormObj); setValidationRule(mValidationRule); setLoading(false);
    }, []);


    useEffect(() => {
        if (infosetting) {
            document.title = `Profile | ${infosetting.title}`;
        }
    }, [infosetting]);


    let loaded = false;
    useEffect(() => {
        if (user && !loaded) {
            loaded = true;
            setFormObj(user);
        }
    }, [user]);


    const handleChange = ({ target }) => {
        const { name, value } = target;
        handleChageCustom(name, value);
    }

    const handleChageCustom = (name, value) => {
        const errors = validate({ [name]: value }, { [name]: validationRule[name] });
        let obj = fillInObject(Object.assign({}, formObj), name, value);
        setFormObj(obj);
        setErrors(Object.assign({}, errors,
            errors ? errors : { [name]: undefined }
        ));
    }

    const onImageChangeHandler = event => {
        onChangeHandler(event, toast).then(({ loadedImage, renderImage }) => {
            //render image
            handleChageCustom("profileImage", renderImage);
            //set for uploading to server
            setProfileImage(loadedImage);
        });
    }

    const handleSubmit = async (e) => {
        e && e.preventDefault();
        const errors = validate(formObj, validationRule);
        if (errors) {
            toast.error("Please enter all required fields")
            setFormError(null);
            setErrors(errors);
            return;
        }

        setLoading(true);
        let form = new FormData();
        if (selectedProfileImage) {
            form.append('profileImage', selectedProfileImage)
        }
        form.append('name', formObj.name);
        form.append('email', formObj.email);
        form.append('state', formObj.state);

        try {
            await authenticationService.update(form);
            setLoading(false);
            toast.success("Profile Updated");
            dispatch(authenticationActions.getCurrentUser());

            if (user.role === "Rider") {
                let mRiderInfoForm = JSON.parse(JSON.stringify(riderInfoForm));
                mRiderInfoForm["user"] = user._id;

                if (!riderInfo) {
                    await apiService.add("riders", mRiderInfoForm);
                } else {
                    await apiService.update("riders", mRiderInfoForm, riderInfo._id);
                }
            }


        } catch (err) {
            setLoading(false);
            toast.error(err.response.data.message);
        }

    }

    return (
        user && formObj ? <div>
            <h3>{user.role} Profile</h3>
            <p className='form-sub-heading'>Manage your {infosetting.title} Profile</p>
            {user.role === "Rider" && riderInfo === null && <div className='alert alert-warning'>
                Complete your rider profile to book a ride with customer!
            </div>}
            <hr className='hr2' />


            <div className='row space-between'>

                <form className="form-container" onSubmit={handleSubmit}>
                    <div className="row mt-20">
                        <img src={getImageURL(formObj.profileImage)} className="profile-img" />
                        <div className="ml-10">
                            <span className="secondary-text name-text">{user.name}</span>
                            <br />
                            <span className="primary-text mt-5">{isMobile ? compressString(user.email, 20) : user.email}</span>
                            <br />
                            <input type="file" className="custom-file-input-p mt-10" name="file" onChange={onImageChangeHandler} />
                        </div>
                    </div>
                    <div className='mt-30'>
                        <FieldsGenerator
                            errors={errors}
                            formObj={formObj}
                            handleChageCustom={handleChageCustom}
                            handleChange={handleChange}
                            isLoading={isLoading}
                            bluePrint={bluePrint}
                            fieldClass="col-sm-12"
                            key={0}
                        />
                        {user.role === "User" && <button
                            type="submit"
                            className="btn btn-primary mt-40 btn-block"
                            onClick={() => handleSubmit()}
                            disabled={isLoading}
                        >
                            {isLoading ? "Please Wait..." : "Save"}
                        </button>}
                    </div>
                </form>
                {user.role === "Rider" && <RiderInfo onChangeForm={setRiderInfoForm} data={riderInfo}/>}
            </div>
            {user.role === "Rider" && <div className='center'>
                <button
                    type="submit"
                    className="btn btn-primary mt-40 profile-submit-btn"
                    onClick={() => handleSubmit()}
                    disabled={isLoading}
                >
                    {isLoading ? "Please Wait..." : "Save"}
                </button>
            </div>}


        </div>
            :
            <div className='height60 row center'>
                <APILoader />
            </div>
    );
};

function mapStateToProps(state) {
    return { ...state.authentication, ...state };
}

export default connect(mapStateToProps)(Profile);