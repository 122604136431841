// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBlX3gXGAivO4W4Qf5b5oezJrkSOZytcpA",
    authDomain: "rate-my-adjuster.firebaseapp.com",
    projectId: "rate-my-adjuster",
    storageBucket: "rate-my-adjuster.appspot.com",
    messagingSenderId: "444516681823",
    appId: "1:444516681823:web:133be5f0b85564e3faf3ea",
    measurementId: "G-MN93VE2JWR"
};



const firebaseInit = () => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
}

export default firebaseInit;