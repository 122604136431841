import React, { Component, useEffect } from 'react';
import { geocodeByAddress, geocodeByPlaceId, getLatLng, } from 'react-places-autocomplete';
import PlacesAutocomplete from 'react-places-autocomplete';
import "./style.css";
import Axios from 'axios';
import config from '../../_config';
const LocationSearchInput = ({ searchAddress, onChange, label }) => {
    const btnId = `current-location-${new Date().getTime()}`;
    useEffect(() => {
        if (searchAddress.address == "") {
            window.document.getElementById(btnId).click();
        }
    }, []);
    const handleChange = address => {
        onChange({ address, latLng: searchAddress.latLng });
    };

    const handleSelect = address => {
        onChange({ address, latLng: searchAddress.latLng });
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                onChange({ address, latLng });
            })
            .catch(error => console.error('Error', error));
    };

    const getCurrentLocation = () => {
        var options = {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 1000
        };
        navigator.geolocation.getCurrentPosition(({ coords }) => {
            geoCoding(null, coords);

        }, (err) => {

        }, options);
    }

    const geoCoding = (address, latLng = null) => {
        let params = latLng ? { key: config.googleMapAPIKey, latlng: latLng.latitude + "," + latLng.longitude } : { key: config.googleMapAPIKey, address }
        Axios.get("https://maps.googleapis.com/maps/api/geocode/json", { params }).then(response => {
            let result = response.data.results[0];
            if (result) {
                onChange({ address: result.formatted_address, latLng: latLng ? { lat: latLng.latitude, lng: latLng.longitude } : { lat: result.geometry.location.lat, lng: result.geometry.location.lng } });

            } else {
                throw Object.assign({
                    message: "No Result Found"
                })
            }
        })
    }
    return (
        <PlacesAutocomplete
            value={searchAddress.address}
            onChange={handleChange}
            onSelect={handleSelect}
        >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <React.Fragment>


                    <div className="input-group">
                        <input
                            {...getInputProps({
                                placeholder: `Search ${label} ...`,
                                className: 'form-control',
                            })}
                        />
                        {/* <div className="input-group-append cursor-pointer" onClick={getCurrentLocation} id={btnId} > */}
                        <div className="input-group-text" id="basic-addon2" onClick={getCurrentLocation} id={btnId}>
                            <i className="fas fa-location-arrow"></i>
                        </div>
                        {/* </div> */}
                    </div>
                    {
                        suggestions.length > 0 && <div className="autocomplete-dropdown-container ">
                            {/* {loading && <div>Loading...</div>} */}
                            {suggestions.map((suggestion, idk) => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active suggestion-cont '
                                    : 'suggestion-item suggestion-cont ';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <div
                                        key={idk}
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    }

                </React.Fragment>
            )}
        </PlacesAutocomplete>
    );

}
export default LocationSearchInput;
