import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import React, { useEffect } from 'react';
const Field = ({ errors, label, labelHidden, icon, iconColor, inputClass, children, labelBr, fieldClass, attribute }) => {
    return (
        <FormControl color="primary" variant="standard" error={errors ? true : false} className={`${fieldClass ? fieldClass : ''} mb-2`}>
            {label && !labelHidden && <InputLabel htmlFor={attribute}>{label}</InputLabel>}
            {children}
            {errors ? <FormHelperText id={`${attribute}-error-text`}>{errors[0]}</FormHelperText> : null}
        </FormControl>
    )
};

export default Field;
