const _nav = [
    // {
    //     icon: "fa-solid fa-home",
    //     title: "Home",
    //     to: "/",
    // },
    {
        icon: "fa-solid fa-user",
        title: "Profile",
        to: "/account/profile",

    },
    {
        icon: "fa-solid fa-star",
        title: "Bookings",
        to: "/account/ratings"
    },
    {
        role: "Rider",
        icon: "fa fa-money",
        title: "Earnings",
        to: "/account/adjusters"
    },
]

export default _nav;