import React from 'react';
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Home from './pages/Anonymous/Home';
import Layout from './pages/Anonymous/Layout';
import { AnonymousRoute } from './_middlewares/_routeMiddleware/AnonymousRoute';
import Signup from './pages/Anonymous/Signup';
import EmailVerification from './pages/Anonymous/EmailVerification';
import Login from './pages/Anonymous/Login';
import ForgotPassword from './pages/Anonymous/ForgotPassword';
import { AuthenticatedRoute } from './_middlewares/_routeMiddleware/AuthenticatedRoute';
import AuthenticatedLayout from './pages/Authenticated/AuthenticatedLayout';
import Profile from './pages/Authenticated/Profile';
import { DataTable, Manage } from './components/CRUD';
import Page from './pages/Anonymous/Page';
import Destination from './pages/Anonymous/Destination';
import LocationPicker from './pages/Anonymous/LocationPicker';
import RideCategory from './pages/Anonymous/RideCategory';

const Router = () => {
    return (
        <Routes>
            <Route path='/' element={<Layout />}>
                <Route index element={<Home />} />
                <Route path='/request' element={<AuthenticatedRoute><Outlet /></AuthenticatedRoute>}>
                    <Route path='destination' element={<Destination />} />
                    <Route path='select-location/:destination_coords/:destination_address' element={<LocationPicker />} />
                    <Route path='select-category/:destination_coords/:destination_address/:pickup_coords/:pickup_address' element={<RideCategory />} />

                </Route>

                <Route path='/p/:slug' element={<Page />} />

                <Route path='auth' element={<AnonymousRoute><Outlet /></AnonymousRoute>}>
                    <Route path="" element={<Navigate to="login" replace={true} />} />
                    <Route path='signup' element={<Signup />} />
                    <Route path='signup/:role' element={<Signup />} />
                    <Route path='login' element={<Login />} />
                    <Route path='email/:type/:email' element={<EmailVerification />} />
                    <Route path='forgot-password' element={<ForgotPassword />} />
                    <Route path="*" element={<Navigate to="login" replace={true} />} />
                </Route>

                <Route path='account' element={<AuthenticatedRoute><AuthenticatedLayout /></AuthenticatedRoute>}>
                    <Route path="" element={<Navigate to="profile" replace={true} />} />
                    <Route path='profile' element={<Profile />} />
                    <Route path="*" element={<Navigate to="profile" replace={true} />} />
                </Route>

                <Route path="*" element={<Navigate to="/" replace={true} />} />
            </Route>
        </Routes>
    );
};

export default Router;