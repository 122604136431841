import React from 'react';
import './style.css';
import getImageURL from '../../_helpers/getImageURL';
import { Link } from 'react-router-dom';

const Footer = ({ infosetting }) => {

    return (
        <div className='footer'>
            <div className='row center'>
                {/* <img src={getImageURL(infosetting.logoSmall)} className='footer-logo' /> */}
                <div className='footer-copy'>
                    {/* © {infosetting.title}. All Rights Reserved */}
                    © Homehunt Delivery. All Rights Reserved
                </div>
            </div>

            <div className='footer-links'>
                <Link to="/p/privacy-policy">Privacy Policy</Link>
                <span>/</span>
                <Link to="/p/terms-of-use">Terms of Use</Link>
                <span>/</span>
                <Link to="/p/content-guidelines">Content Guidelines</Link>
            </div>

            {/* <div className='footer-social-icons'>
                <a href={infosetting.facebookLink} target='_blank'>
                    <i class="fa-brands fa-square-facebook"></i>
                </a>
                <a href={infosetting.instagramLink} target='_blank'>
                    <i class="fa-brands fa-square-instagram"></i>
                </a>
                <a href={infosetting.twitterLink} target='_blank'>
                    <i class="fa-brands fa-square-twitter"></i>
                </a>
                <img src={getImageURL(infosetting.logoSmall)} className='footer-logo' />
            </div> */}
        </div>
    );
};

export default Footer;