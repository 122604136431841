import React, { useEffect, useState } from 'react';
import './style.css';
import { Link, Outlet, useLocation } from 'react-router-dom';
import _nav from './_nav';
import { apiService } from '../../../_services/api.service';
import { connect } from 'react-redux';

const AuthenticatedLayout = ({ user }) => {
    const location = useLocation();
    const [riderInfo, setRiderInfo] = useState(null);

    useEffect(() => {
        if (user && user.role === "Rider") {
            apiService.filter("riders", { query: { user: user._id } })
                .then(res => {
                    if (res.length > 0) {
                        setRiderInfo(res[0])
                    }
                })
        }
    }, [user]);
    return user ? (
        <div className='auth-container height100'>
            <div className='auth-sidebar'>
                <div className='auth-sidebar-container'>
                    {_nav.map((navItem, key) => (!navItem.role || navItem.role === user.role) && <Link
                        className={`auth-sidebar-item ${navItem.to === location.pathname ? 'active' : ''}`}
                        to={navItem.to} key={key}>

                        <i class={navItem.icon}></i>
                        <span>{navItem.title}</span>
                    </Link>)}
                </div>
            </div>

            <div className='auth-content'>
                <div className='crud-container'>
                    <Outlet context={[riderInfo]} />
                </div>
            </div>
        </div>
    ) : null;
};


function mapStateToProps(state) {
    return { ...state.authentication, ...state };
}

export default connect(mapStateToProps)(AuthenticatedLayout);
