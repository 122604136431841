import React from "react";
import { Route, Navigate, useLocation } from "react-router-dom";

export const AnonymousRoute = ({ children }) => {
  let location = useLocation();
  if (localStorage.getItem("token")) {
    return <Navigate
      to={{ pathname: "/account/profile", state: { from: location } }}
    />
  }
  return children;
};

