import React from "react";
import { Route, Navigate, useLocation } from "react-router-dom";

export const AuthenticatedRoute = ({ children }) => {
  let location = useLocation();
  if (!localStorage.getItem("token")) {
    return <Navigate
      to={{ pathname: "/auth/signup", state: { from: location } }}
    />
  }
  return children;
};

