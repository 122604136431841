import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import generateValidation from '../../../_helpers/generateValidation';
import { toast } from 'react-toastify';
import { validate } from 'validate.js';
import fillInObject from '../../../_helpers/fillInObject';
import './style.css'
import FieldsGenerator from '../../../components/FieldsGenerator';
import { Alert } from '@mui/material';
import { authenticationActions } from '../../../_actions/authentication.actions';
import { authenticationService } from '../../../_services/authentication.service';

const ForgotPassword = ({ infosetting, dispatch }) => {
    const navigate = useNavigate();
    const bluePrint = {
        'email': {
            type: String,
            label: "Email",
            placeholder: "Enter Email",
            icon: "ti-email",
            iconColor: "color-primary",
            dataType: "String",
            default: "",
            required: true,
            email: true
        }
    };
    const [formObj, setFormObj] = useState(null);
    const [formError, setFormError] = useState(null);
    const [validationRule, setValidationRule] = useState(null);
    const [errors, setErrors] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [checkTerm, setCheckTerm] = useState(false);

    useEffect(() => {
        if (infosetting) {
            document.title = `ForgotPassword | ${infosetting.title}`;
        }
    }, [infosetting]);

    useEffect(() => {
        let { mFormObj, mValidationRule } = generateValidation(bluePrint);
        setFormObj(mFormObj); setValidationRule(mValidationRule); setLoading(false);
    }, []);


    const handleChange = ({ target }) => {
        const { name, value } = target;
        handleChageCustom(name, value);
    }
    const handleChageCustom = (name, value) => {
        const errors = validate({ [name]: value }, { [name]: validationRule[name] });
        let obj = fillInObject(Object.assign({}, formObj), name, value);
        setFormObj(obj);
        setErrors(Object.assign({}, errors,
            errors ? errors : { [name]: undefined }
        ));
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        const errors = validate(formObj, validationRule);
        if (errors) {
            toast.error("Please enter all required fields")
            setFormError(null);
            setErrors(errors);
            return;
        }

        delete formObj["_id"];
        setLoading(true);
        // dispatch(authenticationActions.login(formObj, history, match, setLoading, handleCallbackError));
        authenticationService.sendResetPasswordEmail(formObj.email)
            .then(naviageToLogin)
            .catch(e => {
                setLoading(false);
                toast.error(e.response.data.message)
                // naviageToLogin(e.response.data);
            })
    }
    const naviageToLogin = (res) => {
        setLoading(false);
        toast.success(res.message);
        navigate("/auth/login");
    }
    return (
        <div className='height100 pd-top'>
            <div className='column center'>
                <h2>
                    ForgotPassword
                </h2>
                <p className='form-sub-heading'>Enter your email to reset your password.</p>
                <form onSubmit={handleSubmit} className='form-container column center'>
                    {formObj &&
                        <FieldsGenerator
                            errors={errors}
                            formObj={formObj}
                            handleChageCustom={handleChageCustom}
                            handleChange={handleChange}
                            loggingIn={false}
                            isLoading={isLoading}
                            bluePrint={bluePrint}
                            key={0}
                        />
                    }

                    {alert.message && <Alert severity={alert.type} className="mt-3">{alert.message} </Alert>}

                    <button type="submit" class="btn btn-block btn-sm mt-20" disabled={isLoading}>
                        {isLoading ? "Please Wait..." : "Reset Password"}
                    </button>

                    <p className='form-sub-heading mt-20'>Back to <Link to="/auth/login" className='secondary-text'>Login</Link></p>

                </form>
            </div>

        </div>
    );
};



function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn, ...state };
}

export default connect(mapStateToProps)(ForgotPassword);