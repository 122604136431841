import './App.css';
import { BrowserRouter, HashRouter } from "react-router-dom";
import Router from './Router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { store } from './_helpers/store';
import { Fragment, useEffect } from 'react';
import firebaseInit from './_helpers/firebaseInit';

function App() {
  useEffect(() => {
    firebaseInit();
  }, []);
  return (
    <HashRouter>
      <ToastContainer />
      <Provider store={store}>
        <Router />
      </Provider>
    </HashRouter>
  );
}

export default App;
