import { infosettingConstants } from "../_constants/infosetting.constants";

export function infosetting(state = null, action) {
  switch (action.type) {
    case infosettingConstants.SET_INFOSETTINGS:
      return action.data[0];

    default:
      return state
  }
}