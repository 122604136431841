import React, { useEffect, useState } from "react";
import Field from "../../_common/field";
import config from "../../_config";
// import AddressLocationField from "../AddressLocationField";
import AddressLocationFieldJS from "../AddressLocationField/AddressLocationFieldJS";
import ImageUploader from "../ImageUploader";
import MultipleImageUploader from "../MultipleImageUploader";
import ParentCheckList from "../ParentCheckList";
import ParentDropDown from "../ParentDropDown";
import PasswordField from "../PasswordField";
import DateTimePicker from 'react-datetime-picker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ChildGrid from "../ChildGrid";
import { Input } from "@mui/material";
const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${config.googleMapAPIKey}&v=3.exp&libraries=geometry,drawing,places`

const FieldsGenerator = ({ formObj, bluePrint, errors, loggingIn, isLoading, handleChange, handleChageCustom, handleChangeLocation, fieldClass }) => {
    return (
        formObj && bluePrint ? Object.keys(bluePrint).map((attribute, key) => {
            const fclass = bluePrint[attribute].fieldClass !== undefined ? bluePrint[attribute].fieldClass : fieldClass;

            if (bluePrint[attribute] && !bluePrint[attribute].hidden && bluePrint[attribute].dataType !== "DefaultDate") {
                if (bluePrint[attribute].dataType === "String" || bluePrint[attribute].dataType === "Number") {
                    return <Field errors={errors[attribute]} key={key} attribute={attribute}  {...bluePrint[attribute]} fieldClass={fclass} >
                        <Input
                            name={attribute}
                            type={bluePrint[attribute].dataType === "String" ? "text" : "number"}
                            placeholder={bluePrint[attribute].placeholder}
                            value={formObj[attribute]}
                            disabled={loggingIn || isLoading || bluePrint[attribute].disabled}
                            onChange={e => handleChange(e)}
                        />
                    </Field>
                }
                else if (bluePrint[attribute].dataType === "TextArea") {
                    return <div className={`form-group ${fclass}`} key={key} attribute={attribute} >
                        {bluePrint[attribute].label && !bluePrint[attribute].labelHidden && <label className="pb-1">{bluePrint[attribute].label}</label>}
                        <textarea name={attribute} rows="7" cols="25"
                            className="form-control"
                            placeholder={bluePrint[attribute].placeholder}
                            value={formObj[attribute]}
                            disabled={loggingIn || isLoading || bluePrint[attribute].disabled} onChange={e => handleChange(e)}></textarea>
                        {errors[attribute] ? <div className="alert alert-danger">{errors[attribute][0]}</div> : null}
                    </div>
                }
                else if (bluePrint[attribute].dataType === "ParentDropDown") {
                    return <ParentDropDown
                        key={key} attribute={attribute}
                        name={attribute}
                        bluePrint={bluePrint[attribute]}
                        value={formObj[attribute]}
                        errors={errors[attribute]}
                        disabled={isLoading}
                        fieldClass={fclass}
                        onChange={parentId => {
                            Object.keys(bluePrint).forEach((at, indx) => {
                                if (bluePrint[at].dataType && bluePrint[at].loadVia === attribute) {
                                    bluePrint[at]["loadViaId"] = parentId;
                                }
                            });
                            // this.setState({ modelType });
                            handleChageCustom(attribute, parentId);
                        }}
                    />

                }
                else if (bluePrint[attribute].dataType === "EnumDropDown") {
                    return <Field label={bluePrint[attribute].label} errors={errors[attribute]} key={key} attribute={attribute} fieldClass={fclass} >
                        <select
                            name={attribute}
                            value={formObj[attribute]}
                            disabled={isLoading}
                            onChange={e => handleChange(e)}
                        >
                            <option value=''>{bluePrint[attribute].placeholder}</option>
                            {bluePrint[attribute].enum.map(en => <option value={en}>{en}</option>)}
                        </select>
                    </Field>
                }
                else if (bluePrint[attribute].dataType === "ParentCheckList") {
                    return <ParentCheckList
                        key={key} attribute={attribute}
                        name={attribute}
                        bluePrint={bluePrint[attribute]}
                        value={formObj[attribute]}
                        errors={errors[attribute]}
                        disabled={isLoading}
                        className={fclass}
                        onChange={parentId => handleChageCustom(attribute, parentId)}
                    />
                }
                else if (bluePrint[attribute].dataType === "PasswordHash") {
                    // return <Field {...bluePrint[attribute]} errors={errors[attribute]} key={key} attribute={attribute} fieldClass={`${fclass} password-form-field`}>
                    //     <PasswordField
                    //         name={attribute}
                    //         // type={bluePrint[attribute].dataType === "String" ? "text" : "number"}
                    //         placeholder={bluePrint[attribute].placeholder}
                    //         value={formObj[attribute]}
                    //         disabled={loggingIn || isLoading}
                    //         onChange={value => handleChageCustom(attribute, value)}
                    //     />
                    // </Field>
                    return <PasswordField
                        {...bluePrint[attribute]} errors={errors[attribute]} key={key} attribute={attribute}
                        name={attribute}
                        // type={bluePrint[attribute].dataType === "String" ? "text" : "number"}
                        placeholder={bluePrint[attribute].placeholder}
                        value={formObj[attribute]}
                        disabled={loggingIn || isLoading}
                        onChange={value => handleChageCustom(attribute, value)}
                    />
                }
                else if (bluePrint[attribute].dataType === "Image") {
                    return <div className={fclass}>
                        {/* <div className={'col-sm-10'}> */}
                        <ImageUploader
                            key={key} attribute={attribute}
                            name={attribute}
                            label={bluePrint[attribute].label}
                            placeholder={bluePrint[attribute].placeholder}
                            value={formObj[attribute]}
                            errors={errors[attribute]}
                            onChange={filePath => handleChageCustom(attribute, filePath)} />
                        {/* </div> */}
                    </div>
                }
                else if (bluePrint[attribute].dataType === "MultipleImages") {
                    return <MultipleImageUploader
                        key={key} attribute={attribute}
                        name={attribute}
                        bluePrint={bluePrint[attribute]}
                        value={formObj[attribute]}
                        errors={errors[attribute]}
                        disabled={isLoading}
                        className={fclass}
                        onChange={parentId => handleChageCustom(attribute, parentId)}
                    />
                }
                else if (bluePrint[attribute].dataType === "AddressBar") {
                    return <Field label={bluePrint[attribute].label} errors={errors[attribute]} key={key} attribute={attribute} fieldClass={fclass} >
                        <AddressLocationFieldJS
                            name={attribute}
                            placeholder={bluePrint[attribute].placeholder}
                            value={formObj[attribute]}
                            location={formObj[attribute + "Location"]}
                            geoFence={formObj[attribute + "GeoFence"]}
                            geoFenceBluePrint={bluePrint[attribute + "GeoFence"]}
                            disabled={isLoading}
                            onChange={(res) => {
                                handleChangeLocation(res);
                            }}
                        />

                    </Field>
                }

                else if (bluePrint[attribute].dataType === "Boolean") {
                    return <div className={`form-group ${fclass ? fclass : "flex-column"}`} key={key} attribute={attribute} >
                        {/* <div className="row"> */}
                        <label>{bluePrint[attribute].label}: </label>
                        <label className="switch ml-2">
                            <input
                                name={attribute}
                                type={"checkbox"}
                                // className="ml-3 mt-1"
                                placeholder={bluePrint[attribute].placeholder}
                                checked={formObj[attribute]}
                                disabled={isLoading}
                                onChange={e => handleChageCustom(attribute, !formObj[attribute])}
                            />
                            <span className="slider round"></span>
                        </label>
                        {/* </div> */}
                    </div>
                }
                else if (bluePrint[attribute].dataType === "Date") {
                    return <Field label={bluePrint[attribute].label} labelBr={true} errors={errors[attribute]} key={key} attribute={attribute} fieldClass={fclass}>
                        <DatePicker
                            wrapperClassName={fclass}
                            selected={formObj[attribute]}
                            minDate={bluePrint[attribute].minDate}
                            onChange={date => handleChageCustom(attribute, date)}
                            disabled={loggingIn || isLoading || bluePrint[attribute].disabled}
                        />
                    </Field>
                } else if (bluePrint[attribute].dataType === "DateTime") {
                    // return <Field label={bluePrint[attribute].label} labelBr={true} errors={errors[attribute]}>
                    return <div className={`form-group ${fclass}`}>
                        <label>{bluePrint[attribute].label}</label>
                        <br />
                        <DateTimePicker
                            value={formObj[attribute]}
                            onChange={date => handleChageCustom(attribute, date)}
                            key={key} attribute={attribute}
                            minDate={bluePrint[attribute].minDate}
                        />
                    </div>
                    // </Field>
                } else if (bluePrint[attribute].dataType === "ChildGrid") {
                    return <ChildGrid
                        className={fclass}
                        key={key} attribute={attribute}
                        name={attribute}
                        bluePrint={bluePrint[attribute]}
                        value={formObj[attribute]}
                        errors={errors[attribute]}
                        isLoading={isLoading}
                        onChange={val => handleChageCustom(attribute, val)}
                    />
                }
                else {
                    return null;
                }
            } else {
                return null;
            }
        }) : null
    )
};

export default FieldsGenerator;