export const checkMimeType = (event, toast) => {
    //getting file object
    let files = event.target.files
    //define message container
    let err = []
    // list allow mime type
    const types = ['image/png', 'image/jpeg', 'image/gif']
    // loop access array
    for (var x = 0; x < files.length; x++) {
        // compare file type find doesn't matach
        if (types.every(type => files[x].type !== type)) {
            // create error message and assign to container   
            err[x] = files[x].type + ' is not a supported format\n';
        }
    };
    for (var z = 0; z < err.length; z++) {// if message not same old that mean has error 
        // discard selected file
        
        toast.error(err[z])
        event.target.value = null
    }
    return (err.length > 0) ? false : true;
}
export const maxSelectFile = (event, toast) => {
    let files = event.target.files
    if (files.length > 1) {
        const msg = 'Only 1 images can be uploaded at a time'
        event.target.value = null
        toast.warn(msg)
        return false;
    }
    return true;
}
export const checkFileSize = (event, toast) => {
    let files = event.target.files
    let size = 2000000
    let err = [];
    for (var x = 0; x < files.length; x++) {
        if (files[x].size > size) {
            err[x] = files[x].type + 'is too large, please pick a smaller file\n';
        }
    };
    for (var z = 0; z < err.length; z++) {// if message not same old that mean has error 
        // discard selected file
        
        toast.error(err[z])
        event.target.value = null
    }
    return true;
}

export const onChangeHandler = (event, toast) => {
    return new Promise((resolve, reject) => {
        var files = event.target.files;
        if (maxSelectFile(event, toast) && checkMimeType(event, toast) && checkFileSize(event, toast)) {
            try {
                let reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onload = (e) => {
                    let loadedImage = e.target.result;
                    resolve({ loadedImage: files[0], renderImage: loadedImage });
                }
            } catch (error) {
                
                toast.error('Invalid Image');
                reject('Invalid Image');
            }
        }
    })

}