import React from 'react';
import { Oval } from 'react-loader-spinner'
export const APILoader = ({ height, color }) => {
    return (
        <Oval
            visible={true}
            height={`${height ? height : 80}`}
            width={`${height ? height : 80}`}
            color={color ? color : "#7334f1"}
            secondaryColor='#361874'
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
        />
    )
}