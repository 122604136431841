const generateValidation = (bluePrint) => {
    let mValidationRule = {};
    
    Object.keys(bluePrint).forEach((attribute, key) => {
        if (!bluePrint[attribute].hidden && (bluePrint[attribute].dataType !== "Boolean" || bluePrint[attribute].dataType !== "DefaultDate")) {
            mValidationRule[attribute] = (bluePrint[attribute].required) ? { presence: { allowEmpty: false } } : null;

            if (bluePrint[attribute].pattern && bluePrint[attribute].required) {
                mValidationRule[attribute]["format"] = {
                    pattern: bluePrint[attribute].pattern,
                    message: `^Please enter valid ${bluePrint[attribute].label}`
                }
            }
            if (bluePrint[attribute].minLength) {
                mValidationRule[attribute]["length"] = {
                    minimum: bluePrint[attribute].minLength,
                    message: `must be at least ${bluePrint[attribute].minLength} characters`
                }
            }
            mValidationRule[attribute] = (bluePrint[attribute].required && bluePrint[attribute].email) ? {
                presence: { allowEmpty: false },
                email: {
                    message: "Doesn't look like a valid email"
                }
            } : mValidationRule[attribute];
        }
    });

    let mFormObj = {
        _id: ""
    }
    Object.keys(bluePrint).forEach((attribute, key) => {
        if (!bluePrint[attribute].hidden && (bluePrint[attribute].dataType === "Date" || bluePrint[attribute].dataType === "DefaultDate")) {
            mFormObj[attribute] = new Date();
        } else {
            mFormObj[attribute] = bluePrint[attribute].default;
        }
    });
    return { mValidationRule, mFormObj };
};

export default generateValidation;