import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import { connect } from "react-redux";
import PaginationComponent from "react-reactstrap-pagination";
import Field from './../_common/field';
import pluralize from "pluralize";
import Notification from "./../_common/notification";
import { toast } from 'react-toastify';
import validate from 'validate.js';
// import { settingsService } from "../../../_services/settings.service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateTimePicker from 'react-datetime-picker';
import ImageUploader from "./ImageUploader";
import io from 'socket.io-client';
import AddressLocationFieldJS from "./AddressLocationField/AddressLocationFieldJS";
import RTEField from "./RTEField";
// import { GoogleMap, Marker } from "react-google-maps";
import PasswordField from "./PasswordField";
import { apiService } from "../_services/api.service";
import config from "../_config";
import MultipleImageUploader from "./MultipleImageUploader";
import ParentDropDown from "./ParentDropDown";
import ParentCheckList from "./ParentCheckList";
import { authenticationActions } from "../_actions/authentication.actions";
import getImageURL from "./../_helpers/getImageURL";
import { APILoader } from "./APILoader";
import withRouter from "./withRouter";
import { Input } from "@mui/material";
import RatingSelector from "./RatingSelector";
const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=${config.googleMapAPIKey}&v=3.exp&libraries=geometry,drawing,places`
const prefix = "/";

// import { useLocation } from 'react-router-dom'


// eslint-disable-next-line no-extend-native
Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};
class DataTable extends Component {
  state = {
    activePagination: {
      pageLoading: true,
      selectedPage: 1,
      pageSize: 10,
      maxPaginationNumbers: 9
    },
    searching: false,
    modelType: null,
    list: [],
    listFiltered: [],
    route: "",
    loaded: false,
    service: null
  };

  mounted = true;
  componentDidMount() {
    this.mounted = true;
    let { location } = this.props;
    const { pathname } = location;
    let route = pathname.split("/")[2];
    if (route) {
      route = pluralize.plural(route.split(prefix)[0]);

      document.title = `${this.capitalizeFirstLetter(route)} | Home Hunt Delivery`;

      let service = apiService;
      this.setState({ route, service });

      service.type(route).then(m => {
        this.setState({ modelType: m });
      })

      let mEntity = null;

      let { user } = authenticationActions.getCurrentUserLocal();
      mEntity = user;
      this.fetch(this.props, route, user, service);

      let socket = io(config.baseUrl, config.socketHeader);
      let routeName = pluralize.singular(route);
      socket.on("new-" + routeName, (m) => {
        debugger;
        if (m["user"] === mEntity._id) {
          let list = this.state.list;
          list.insert(0, m);
          this.setState({ list, listFiltered: list })
        }
      });

      socket.on("update-" + routeName, (m) => {
        let list = this.state.list;
        let foundIndex = list.findIndex(n => n._id === m._id);
        if (foundIndex !== -1)
          list[foundIndex] = m;
        this.setState({ list, listFiltered: list })
      });
      socket.on("delete-" + routeName, (m) => {
        let list = this.state.list;
        let foundIndex = list.findIndex(n => n._id === m);
        if (foundIndex !== -1)
          list.splice(foundIndex, 1);
        this.setState({ list, listFiltered: list })
      });
    }

  }
  componentWillUnmount() {
    this.mounted = false;
  }
  componentWillReceiveProps(props) {
    if (this.mounted) {
      let { location } = props;
      const { pathname } = location;
      let route = pathname.split("/")[2];
      if (route) {
        route = pluralize.plural(route.split(prefix)[0]);
        let service = apiService;
        this.setState({ route, service });

        service.type(route).then(m => {
          this.setState({ modelType: m });
        });


        let { user } = authenticationActions.getCurrentUserLocal();
        this.fetch(this.props, route, user, service);


      }

    }

  }

  fetch = (props, route, user, service) => {
    try {
      const { params } = props;
      let filterQuery = {};
      if (params.pageid) {
        filterQuery = JSON.parse(atob(params.pageid));
      }
      filterQuery["user"] = user._id;

      service.filter(route, { query: filterQuery, sortQuery: { dateTime: -1 } })
        .then(list => {
          this.setState({ list, listFiltered: list, loaded: true })
        });
    } catch (error) {
      console.log({ error });
    }

  }
  doSearch = ({ target }) => {
    if (!this.state.searching) {

      this.setState({ searching: true })
      const { value } = target;
      let tempTistFiltered = this.state.list;
      // let listFiltered = JSON.parse(JSON.stringify(this.state.list));
      let searchText = value.toLowerCase();
      let newTempList = [];
      debugger;
      if (tempTistFiltered && tempTistFiltered.length > 0) {
        tempTistFiltered.forEach(item => {
          try {
            Object.keys(this.state.modelType.bluePrint).forEach((attribute, key) => {
              if (!this.state.modelType.bluePrint[attribute].hidden) {
                if ((this.state.modelType.bluePrint[attribute].dataType === "String" ||
                  this.state.modelType.bluePrint[attribute].dataType === "EnumDropDown") &&
                  item[attribute] && item[attribute].toLowerCase().includes(searchText)) {
                  console.log(item[attribute].toLowerCase());
                  // newTempList.push(item);
                  throw Object.assign(new Error({ status: false }));
                } else if (this.state.modelType.bluePrint[attribute].dataType === "Number" &&
                  item[attribute] && item[attribute].toString().toLowerCase().includes(searchText)) {
                  throw Object.assign(new Error({ status: false }));
                }
              }
            })
          } catch (error) {
            newTempList.push(item);
          }


        })

      }
      this.setState({ listFiltered: newTempList, searching: false })
    }
  }
  handleSelectedActivePage = selectedPage => {
    let activePagination = this.state.activePagination;
    activePagination.selectedPage = selectedPage;
    this.setState({ activePagination });
  };
  generateSubParams = (route, subRoute, id) => {
    let newR = `/account/${route}${prefix}${subRoute}/${id}`;
    if (this.props.location.search !== "") {
      newR += `?callby=${btoa(this.props.location.search)}`;
    }
    return newR;
  }
  generateNavigateParams = (params, obj) => {
    debugger;
    let pramsObj = {}
    params.forEach(p => {
      pramsObj[p.name] = obj[p.field]
    })
    return JSON.stringify(pramsObj);
  }
  restrictNavigationParams = (restrict, obj) => {
    let allow = restrict ? false : true;
    restrict && restrict.forEach((r, i) => {
      if (r.value === obj[r.field]) {
        allow = true;
      }
    })
    return allow;
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  render() {
    // const list = categoriesData
    const {
      // list,
      listFiltered, modelType, route, loaded } = this.state;
    return (
      <div>
        {modelType &&
          <div>
            <div className="datatable-header">
              <h3>{this.capitalizeFirstLetter(pluralize.plural(route))}</h3>
              {modelType.permission && modelType.permission.create &&
                <Link to={`/account/${pluralize.singular(route)}${prefix}manage`} className="btn btn-sm">
                  Add New
                </Link>
              }
            </div>
            {loaded ? <Fragment>
              <div className="form-group">
                <Input name="search" type="text" placeholder="Search..." className="form-control" disabled={this.state.searching} onChange={e => this.doSearch(e)} />
              </div>

              <div className="table-responsive">
                <table className="mt-10 table">
                  <thead>
                    <tr>
                      {Object.keys(modelType.bluePrint).map((th, key) => {
                        if (modelType.bluePrint[th].displayOnHeader && !modelType.bluePrint[th].hidden && th !== "user")
                          return <th scope="col" key={key}>{modelType.bluePrint[th].label}</th>
                        else
                          return null;
                      })}
                      {
                        (modelType.permission.create || modelType.permission.edit || modelType.permission.delete) &&
                        <th key={100}>Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {listFiltered.length === 0 ?
                      <tr>
                        <td colspan="12" className="text-center">
                          {modelType.modelDisplayName} not found
                        </td>
                      </tr>
                      : listFiltered
                        .slice(
                          (this.state.activePagination.selectedPage - 1) *
                          this.state.activePagination.pageSize,
                          this.state.activePagination.selectedPage *
                          this.state.activePagination.pageSize
                        )
                        .map((formObj, index) => (
                          <tr key={formObj._id.toString()}>
                            {
                              Object.keys(modelType.bluePrint).map((td, key) => {
                                if (modelType.bluePrint[td].displayOnHeader && !modelType.bluePrint[td].hidden)
                                  if (modelType.bluePrint[td].dataType === "String" ||
                                    modelType.bluePrint[td].dataType === "EnumDropDown" ||
                                    modelType.bluePrint[td].dataType === "Number" ||
                                    modelType.bluePrint[td].dataType === "AddressBar")
                                    return <td key={key}>{formObj[td]}</td>

                                  else if (modelType.bluePrint[td].dataType === "Image")
                                    return <td key={key}>
                                      <img src={getImageURL(formObj[td])} alt={td} style={{ height: 70 }} />
                                    </td>

                                  else if (modelType.bluePrint[td].dataType === "Boolean")
                                    return <td key={key}>{formObj[td] ? "Yes" : "No"}</td>

                                  else if (modelType.bluePrint[td].dataType === "ParentDropDown" && td !== "user")
                                    return <td key={key}>{formObj[td + "Obj"][modelType.bluePrint[td].displayfield]}</td>

                                  else if (modelType.bluePrint[td].dataType === "Date" || modelType.bluePrint[td].dataType === "DateTime" || modelType.bluePrint[td].dataType === "DefaultDate")
                                    return <td key={key}>{new Date(formObj[td]).toLocaleDateString()} {new Date(formObj[td]).toLocaleTimeString()}</td>
                                  else if (modelType.bluePrint[td].dataType === "RatingSelector")
                                    return <td key={key} onClick={() => this.props.navigate(`/adjuster/${formObj._id}`)}>
                                      {formObj[td] === 0 ?
                                        <span className="secondary-text text-sm cursor-pointer">Click to Review</span>
                                        : <RatingSelector value={formObj[td]} />}
                                    </td>

                                  else
                                    return null;
                                else
                                  return null;
                              })

                            }
                            {
                              (modelType.permission.create || modelType.permission.edit || modelType.permission.delete) && <td>
                                {modelType.permission && modelType.permission.detail &&
                                  <Link to={this.generateSubParams(route, 'detail', formObj._id)} className="mr-2"> <i className="fa fa-info"></i></Link>
                                }
                                {modelType.permission && modelType.permission.edit &&
                                  <Link to={`/account/${pluralize.singular(route)}${prefix}manage/${formObj._id}`}>
                                    <i className="fa fa-edit"></i>
                                  </Link>
                                }
                                {modelType.permission && modelType.permission.delete &&
                                  <Link to={`/account/${route}${prefix}delete/${formObj._id}`} className="ml-10 text-danger">
                                    <i className="fa fa-trash"></i>
                                  </Link>
                                }

                                {/* {modelType.actions && modelType.actions.map(action => this.restrictNavigationParams(action.restrict, formObj) && <div className="row">
                              <Link
                                to={`/account/${action.route}?filter=${this.generateNavigateParams(action.params, formObj)}`}
                                className="ml-2 text-primary"
                              >
                                {action.value}
                              </Link>

                            </div>
                            )} */}
                              </td>
                            }

                          </tr>
                        ))
                    }
                  </tbody>
                </table>
              </div>


              <PaginationComponent
                totalItems={listFiltered.length}
                pageSize={this.state.activePagination.pageSize}
                onSelect={this.handleSelectedActivePage}
                maxPaginationNumbers={
                  this.state.activePagination.maxPaginationNumbers
                }
                activePage={this.state.activePagination.selectedPage}
              />

            </Fragment> :
              <div className="height60 row center">
                <APILoader />
              </div>}
          </div>
        }
      </div>
    );
  }
}

class Detail extends Component {
  state = {
    formObj: null,
    modelType: null,
    loading: true,
    status: false,
    delete: false,
    callBy: null,
    route: "",
    enntity: "",
    service: null
  }

  componentDidMount() {
    let { location } = this.props;
    const { pathname } = location;
    let route = pathname.split("/")[2];
    if (route) {
      route = pluralize.plural(route.split(prefix)[0]);
      let service = apiService;
      this.setState({ route, service });

      service.type(route).then(m => {
        this.setState({ modelType: m });
      })
      service.getSingle(route, this.props.params.id).then(formObj => {
        this.setState({ formObj, status: formObj.status, delete: pathname.includes("delete"), loading: false })
      }).catch(e => {
        console.log(e.response)
      });
    }


  }

  backToListLink = () => {
    let { search } = this.props.location;
    let query = new URLSearchParams(search);
    const callBy = query.get("callby");
    const { route } = this.state;
    let link = `/account/${route}`;

    if (callBy) {
      link += atob(callBy);
    }
    return link;
  }


  render() {
    const { formObj, loading, modelType, route, callBy, service } = this.state;
    return (
      <div>
        {modelType &&
          <div className="d-flex justify-content-end">
            <Link to={this.backToListLink}>Back to List {callBy}</Link>
          </div>}
        <div className="container m-padding p-3" id='main'>
          <div className="widget-panel widget-submit">

            {
              loading ? <span><i className="text-muted icon-wait"></i> Loading...</span> :
                formObj && modelType ? <div className="animated fadeIn" >
                  <Row>
                    <Col lg={8}>
                      <div className="p-3">
                        <Table striped hover>
                          <tbody>
                            {
                              Object.keys(modelType.bluePrint).map((td, key) => {
                                if (!modelType.bluePrint[td].hidden && modelType.bluePrint[td].dataType) {
                                  return <tr key={key}>
                                    <td style={{ width: '30%' }}>{modelType.bluePrint[td].label}</td>

                                    {(modelType.bluePrint[td].dataType === "String" ||
                                      modelType.bluePrint[td].dataType === "EnumDropDown" ||
                                      modelType.bluePrint[td].dataType === "Number") &&
                                      <td key={key}>{formObj[td]}</td>
                                    }
                                    {(modelType.bluePrint[td].dataType === "PasswordHash") &&
                                      <td key={key} >
                                        *******
                                      </td>
                                    }

                                    {(modelType.bluePrint[td].dataType === "RichText") &&
                                      <td key={key} >
                                        <div dangerouslySetInnerHTML={{ __html: formObj[td] }}></div>
                                      </td>
                                    }
                                    {(modelType.bluePrint[td].dataType === "AddressBar") &&
                                      <td key={key}>
                                        <div classNme="row">
                                          <div className="col-sm-12">{formObj[td]}</div>
                                          <div className="col-sm-12 mt-2">
                                            <AddressLocationFieldJS
                                              name={td}
                                              placeHolder={td}
                                              value={formObj[td]}
                                              location={formObj[td + "Location"]}
                                              viewable
                                            />
                                            {/* <GoogleMapComponent
                                              googleMapURL={googleMapURL}
                                              loadingElement={<div style={{ height: `100%` }} />}
                                              containerElement={<div style={{ height: `300px` }} />}
                                              mapElement={<div style={{ height: `100%` }} />}>
                                              <GoogleMap
                                                defaultZoom={config.mapZoom}
                                                defaultCenter={{ lat: formObj[td + "Location"].coordinates[0], lng: formObj[td + "Location"].coordinates[1] }}
                                              >
                                                <Marker position={{ lat: formObj[td + "Location"].coordinates[0], lng: formObj[td + "Location"].coordinates[1] }} />
                                              </GoogleMap>
                                            </GoogleMapComponent> */}
                                          </div>
                                        </div>
                                      </td>
                                    }

                                    {(modelType.bluePrint[td].dataType === "Image") && <td key={key}>
                                      <img src={getImageURL(formObj[td])} alt={td} style={{ height: 100 }} />
                                    </td>}

                                    {(modelType.bluePrint[td].dataType === "MultipleImages") && <td key={key}>
                                      <div className="row">
                                        {formObj[td].map(item => <div className="col-sm-4">
                                          <img src={getImageURL(item.path)} alt={td} style={{ width: '100%', height: 'auto' }} />
                                        </div>
                                        )}
                                      </div>
                                    </td>}

                                    {(modelType.bluePrint[td].dataType === "Boolean") &&
                                      <td key={key}>{formObj[td] ? "Yes" : "No"}</td>
                                    }


                                    {(modelType.bluePrint[td].dataType === "ParentDropDown") &&
                                      <td key={key}>{formObj[td + "Obj"][modelType.bluePrint[td].displayfield]}</td>
                                    }

                                    {(modelType.bluePrint[td].dataType === "ParentCheckList") &&
                                      <td key={key}>
                                        {formObj[td].map(li => <li>
                                          {li.obj[modelType.bluePrint[td].displayfield]}
                                        </li>)}
                                      </td>
                                    }

                                    {(modelType.bluePrint[td].dataType === "Date" || modelType.bluePrint[td].dataType === "DateTime" || modelType.bluePrint[td].dataType === "DefaultDate") &&
                                      <td key={key}>{new Date(formObj[td]).toLocaleDateString()} {new Date(formObj[td]).toLocaleTimeString()}</td>
                                    }

                                  </tr>
                                } else
                                  return null;
                              })
                            }

                            {this.state.delete &&
                              <tr>
                                <td>
                                  Comfirmation:
                                </td>
                                <td>
                                  <button className="btn btn-danger btn-sm" type="button" onClick={() => { service.delete(route, formObj._id).then(d => { this.props.navigate(`/account/${route}`) }) }}>
                                    <i className="fa fa-trash"></i> Delete
                                  </button>
                                  <button className="btn btn-info btn-sm text-white ml-1" type="button" onClick={() => { this.props.navigate(`/account/${route}`) }}>Cancel</button>
                                </td>
                              </tr>
                            }
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </div>
                  : <span><i className="text-muted icon-ban"></i> Not found</span>
            }
          </div>
        </div>
      </div>
    )

  }
}

class Manage extends Component {
  state = {
    formObj: null,
    validationRule: null,
    errors: {},
    formError: null,
    loaded: 0,
    pageLoading: true,
    isLoading: true,
    modelType: null,
    single: false,
    route: "",
    service: null
  }

  componentDidMount() {
    let { location } = this.props;
    const { pathname } = location;
    let route = pathname.split("/")[2];
    route = pluralize.plural(route.split(prefix)[0]);

    let service = apiService;
    this.setState({ route, service });

    const query = new URLSearchParams(this.props.location.search);
    if (query.get("single")) {
      this.setState({ single: true })
    }


    service.type(route).then(modelType => {
      //Define Validation Rule
      delete modelType.bluePrint["user"];

      let validationRule = {};
      Object.keys(modelType.bluePrint).forEach((attribute, key) => {
        if (!modelType.bluePrint[attribute].hidden && (modelType.bluePrint[attribute].dataType !== "Boolean" || modelType.bluePrint[attribute].dataType !== "DefaultDate")) {
          if (modelType.bluePrint[attribute].required) {
            validationRule[attribute] = { presence: { allowEmpty: false } }
          }
        }
      });


      this.setState({ modelType, validationRule });
      if (this.props.params.id) {
        service.getSingle(route, this.props.params.id).then(formObj => {
          Object.keys(modelType.bluePrint).forEach((attribute, key) => {
            if (!modelType.bluePrint[attribute].hidden)
              if (modelType.bluePrint[attribute].dataType === "DateTime") {
                formObj[attribute] = new Date(formObj[attribute]);
              }
              else if (modelType.bluePrint[attribute].dataType === "RichText")
                formObj[attribute] = (!formObj[attribute]) ? "" : formObj[attribute];

            Object.keys(modelType.bluePrint).forEach((at, indx) => {
              if (modelType.bluePrint[at].dataType && modelType.bluePrint[at].loadVia === attribute) {
                modelType.bluePrint[at]["loadViaId"] = formObj[attribute];
              }
            });

          });

          this.setState({ formObj, isLoading: false })
        }).catch(m => {
          this.props.navigate(`/${route}`)
        })
      } else {
        let formObj = { _id: "" }
        Object.keys(modelType.bluePrint).forEach((attribute, key) => {
          if (
            // !modelType.bluePrint[attribute].hidden &&
            (modelType.bluePrint[attribute].dataType === "Date" || modelType.bluePrint[attribute].dataType === "DefaultDate")) {
            formObj[attribute] = new Date();
          } else {
            formObj[attribute] = modelType.bluePrint[attribute].default;
          }
        });
        this.setState({ formObj, isLoading: false });
      }
    })
  }
  componentDidUpdate(nextProps, nextState) {
    if (this.state.formObj !== null && nextState.formObj === null) {
      const { modelType } = this.state;
      if (modelType.bluePrint.state && this.props.user && this.props.user.state) {
        const parentId = this.props.user.state;

        Object.keys(modelType.bluePrint).forEach((at, indx) => {
          if (modelType.bluePrint[at].dataType && modelType.bluePrint[at].loadVia === 'state') {
            modelType.bluePrint[at]["loadViaId"] = parentId;
          }
        });
        this.setState({ modelType });
        this.handleChageCustom('state', parentId);
      }
    }
  }

  backToListLink = () => {
    let { search } = this.props.location;
    let query = new URLSearchParams(search);
    const callBy = query.get("callby");
    const { route } = this.state;
    let link = `/account/${route}`;

    if (callBy) {
      link += atob(callBy);
    }
    return link;
  }
  convertAllParametersToString(ad) {
    for (var prop in ad) {
      ad[prop] += "";
    }
    return ad;
  }
  static get RULES() {
    return {
      title: {
        presence: {
          allowEmpty: false
        }
      }
    };
  }
  handleChange({ target }) {
    const { name, value } = target;
    const errors = validate({ [name]: value }, { [name]: this.state.validationRule[name] });
    let obj = this.fillInObject(Object.assign({}, this.state.formObj), name, value);
    this.setState({ formObj: obj, errors: Object.assign({}, this.state.errors, errors ? errors : { [name]: undefined }) });
  }
  handleChageCustom = (name, value) => {
    const errors = validate({ [name]: value }, { [name]: this.state.validationRule[name] });
    let obj = this.fillInObject(Object.assign({}, this.state.formObj), name, value);
    this.setState({ formObj: obj, errors: Object.assign({}, this.state.errors, errors ? errors : { [name]: undefined }) });
  }
  handleChangeLocation = (res) => {
    if (this.state.formObj) {
      let tFromObj = JSON.parse(JSON.stringify(this.state.formObj));
      Object.keys(res).forEach(rK => {
        tFromObj[rK] = res[rK];
      });
      this.setState({ formObj: tFromObj });
    }
  }
  handleChangeBoolean({ target }) {
    const { name } = target;
    let obj = this.fillInObject(Object.assign({}, this.state.formObj), name, !this.state.formObj[name]);
    this.setState({
      formObj: obj
    });
  }

  fillInObject(obj, name, value) {
    obj[name] = value;
    return obj;
  }
  isContainProps(ad) {
    let rt = false;
    for (var prop in ad) {
      if (ad[prop] && ad.hasOwnProperty(prop)) {
        rt = true;
        // handle prop as required
      }
    }
    return rt;
  }
  handleSubmit(e) {
    e.preventDefault();
    // if (this.state.formObj.image) {
    let { service, route } = this.state;
    let { formObj } = JSON.parse(JSON.stringify(this.state));
    const errors = validate(formObj, this.state.validationRule);

    if (errors) {
      toast.error("Form data is Invalid")
      return this.setState({ formError: null, errors });
    }

    let { user } = authenticationActions.getCurrentUserLocal();
    formObj["user"] = user._id;

    this.setState({ isLoading: true }, () => {
      if (!formObj._id) {
        service.add(route, formObj).then(res => {
          if (!this.state.single)
            this.props.navigate(`/account/${route}`)

        }).catch(err => {
          toast.error(err.response.data.message);
          this.setState({ isLoading: false })
        })
      } else {
        service.update(route, formObj, formObj._id).then(res => {
          if (!this.state.single) {
            this.props.navigate(`/account/${route}`)
          } else {
            toast.success("Successfully Updated!");
            this.setState({ isLoading: false })
          }

        }).catch(err => {
          toast.error(err.response.data.message);
          this.setState({ isLoading: false })
        })
      }
    })

    // } else {
    //   toast.error("Please Upload Packege Image")
    // }
  }
  render() {
    const { formObj, isLoading, errors, modelType, route, single } = this.state;
    const { params } = this.props;

    return isLoading ? <span><i className="text-muted icon-wait"></i> Loading...</span> :
      formObj ? <div className="form-container" >
        <h3 className="title">
          <Link to={`/account/${route}`} className="secondary-text mr-5">
            <i className="fa fa-chevron-left"></i>
          </Link>
          {params.id ? "Edit" : "Add"} {pluralize.singular(modelType.modelDisplayName)}
        </h3>


        <form onSubmit={e => this.handleSubmit(e)} className="mt-20" >
          {this.state.formError ? (
            <Notification type="danger" onCloseBtnClick={e => this.setState({ formError: null })}>
              {this.state.formError}
            </Notification>
          ) : null}
          <div className="p-2">
            {
              Object.keys(modelType.bluePrint).map((attribute, key) => {
                if (!modelType.bluePrint[attribute].hidden && modelType.bluePrint[attribute].dataType !== "DefaultDate") {
                  if (modelType.bluePrint[attribute].dataType === "String" || modelType.bluePrint[attribute].dataType === "Number") {
                    return <Field label={modelType.bluePrint[attribute].label} errors={errors[attribute]} key={key}>
                      <Input
                        name={attribute}
                        type={modelType.bluePrint[attribute].dataType === "String" ? "text" : "number"}
                        placeholder={modelType.bluePrint[attribute].placeholder}
                        value={formObj[attribute]}
                        disabled={isLoading}
                        onChange={e => this.handleChange(e)}
                      />
                    </Field>
                  }
                  else if (modelType.bluePrint[attribute].dataType === "PasswordHash") {
                    return <Field label={modelType.bluePrint[attribute].label} errors={errors[attribute]} key={key}>
                      <PasswordField
                        name={attribute}
                        // type={modelType.bluePrint[attribute].dataType === "String" ? "text" : "number"}
                        placeholder={modelType.bluePrint[attribute].placeholder}
                        value={formObj[attribute]}
                        disabled={isLoading}
                        onChange={value => this.handleChageCustom(attribute, value)}

                      />
                    </Field>
                  } else if (modelType.bluePrint[attribute].dataType === "RichText") {
                    return <div className="form-group">
                      <label>{modelType.bluePrint[attribute].label}</label>
                      <RTEField
                        value={formObj[attribute]}
                        onChange={value => this.handleChageCustom(attribute, value)}
                      />
                    </div>
                  } else if (modelType.bluePrint[attribute].dataType === "AddressBar") {
                    return <Field label={modelType.bluePrint[attribute].label} errors={errors[attribute]} key={key}>
                      <AddressLocationFieldJS
                        name={attribute}
                        type={modelType.bluePrint[attribute].dataType === "String" ? "text" : "number"}
                        placeholder={modelType.bluePrint[attribute].placeholder}
                        value={formObj[attribute]}
                        location={formObj[attribute + "Location"]}
                        disabled={isLoading}
                        googleMapURL={googleMapURL}
                        onChange={this.handleChangeLocation}

                      />
                    </Field>
                  } else if (modelType.bluePrint[attribute].dataType === "EnumDropDown") {
                    return <Field label={modelType.bluePrint[attribute].label} errors={errors[attribute]} key={key}>
                      <select
                        name={attribute}
                        value={formObj[attribute]}
                        disabled={isLoading}
                        onChange={e => this.handleChange(e)}
                      >
                        <option value={null}>{modelType.bluePrint[attribute].placeholder}</option>
                        {modelType.bluePrint[attribute].enum.map(en => <option value={en}>{en}</option>)}
                      </select>
                    </Field>
                  } else if (modelType.bluePrint[attribute].dataType === "ParentDropDown") {
                    return <ParentDropDown
                      key={key}
                      name={attribute}
                      bluePrint={modelType.bluePrint[attribute]}
                      value={formObj[attribute]}
                      errors={errors[attribute]}
                      disabled={isLoading}
                      onChange={parentId => {
                        Object.keys(modelType.bluePrint).forEach((at, indx) => {
                          if (modelType.bluePrint[at].dataType && modelType.bluePrint[at].loadVia === attribute) {
                            modelType.bluePrint[at]["loadViaId"] = parentId;
                          }
                        });
                        this.setState({ modelType });
                        this.handleChageCustom(attribute, parentId);
                      }}
                    />

                  } else if (modelType.bluePrint[attribute].dataType === "ParentCheckList") {
                    return <ParentCheckList
                      key={key}
                      name={attribute}
                      bluePrint={modelType.bluePrint[attribute]}
                      value={formObj[attribute]}
                      errors={errors[attribute]}
                      disabled={isLoading}
                      onChange={parentId => this.handleChageCustom(attribute, parentId)}
                    />
                  } else if (modelType.bluePrint[attribute].dataType === "MultipleImages") {
                    return <MultipleImageUploader
                      key={key}
                      name={attribute}
                      bluePrint={modelType.bluePrint[attribute]}
                      value={formObj[attribute]}
                      errors={errors[attribute]}
                      disabled={isLoading}
                      onChange={parentId => this.handleChageCustom(attribute, parentId)}
                    />
                  } else if (modelType.bluePrint[attribute].dataType === "Image") {
                    return <div className="col-sm-6">
                      <ImageUploader
                        key={key}
                        name={attribute}
                        label={modelType.bluePrint[attribute].label}
                        placeholder={modelType.bluePrint[attribute].placeholder}
                        value={formObj[attribute]}
                        errors={errors[attribute]}
                        onChange={filePath => this.handleChageCustom(attribute, filePath)} />
                    </div>
                  } else if (modelType.bluePrint[attribute].dataType === "Boolean") {
                    return <div className="form-group" key={key}>
                      <label>{modelType.bluePrint[attribute].label}: </label>
                      <input
                        name={attribute}
                        type={"checkbox"}
                        className="ml-3 mt-1"
                        placeholder={modelType.bluePrint[attribute].placeholder}
                        checked={formObj[attribute]}
                        disabled={isLoading}
                        onChange={e => this.handleChangeBoolean(e)}
                      />
                    </div>
                  } else if (modelType.bluePrint[attribute].dataType === "Date") {
                    return <Field label={modelType.bluePrint[attribute].label} labelBr={true} errors={errors[attribute]} key={key}>
                      <DatePicker
                        selected={formObj[attribute]}
                        onChange={date => this.handleChageCustom(attribute, date)}
                      />
                    </Field>
                  } else if (modelType.bluePrint[attribute].dataType === "DateTime") {
                    // return <Field label={modelType.bluePrint[attribute].label} labelBr={true} errors={errors[attribute]}>
                    return <div className="form-group">
                      <label>{modelType.bluePrint[attribute].label}</label>
                      <br />
                      <DateTimePicker
                        value={formObj[attribute]}
                        onChange={date => this.handleChageCustom(attribute, date)}
                        key={key}
                      />
                    </div>
                    // </Field>
                  } else
                    return null;
                } else
                  return null;
              })
            }
            <div className="row mt-20">
              <button className={`btn  btn-sm btn-block`} disabled={isLoading} onClick={e => this.handleSubmit(e)}>Save</button>

            </div>
          </div>
        </form>
      </div>
        : <span><i className="text-muted icon-ban"></i> Not found</span>

  }
}


function mapStateToProps(state) {
  return { ...state.authentication, ...state };
}


const connectedDataTable = connect(mapStateToProps)(withRouter(DataTable));
const connectedDetail = connect(mapStateToProps)(withRouter(Detail));
const connectedManage = connect(mapStateToProps)(withRouter(Manage));
export { connectedDataTable as DataTable, connectedDetail as Detail, connectedManage as Manage };

