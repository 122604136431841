import React, { Fragment, useEffect } from 'react';
import './style.css';
import Footer from '../../../components/Footer';
import Header from '../../../components/Header';
import { Outlet, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { infosettingActions } from '../../../_actions/infosetting.actions';
import { APILoader } from '../../../components/APILoader';
import { authenticationActions } from '../../../_actions/authentication.actions';

const Layout = ({ dispatch, infosetting, authentication, user }) => {
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(infosettingActions.fetch());
    }, []);

    useEffect(() => {
        if (authentication && authentication.loggedIn && !authentication.user) {
            dispatch(authenticationActions.getCurrentUser(navigate));
        }
    }, [authentication]);

    const handleLogout = () => {
        dispatch(authenticationActions.logout(navigate));
    }

    return infosetting ? (
        <Fragment>
            <Header infosetting={infosetting} authentication={authentication} handleLogout={handleLogout} />
            <Outlet />
            <Footer infosetting={infosetting} />
        </Fragment>
    ) :
        <div className='height100 row center'>
            <APILoader />
        </div>

};

function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    return { loggingIn, ...state };
}

export default connect(mapStateToProps)(Layout);