const config = {
    baseUrl: '',
    // baseUrl: 'https://homehuntdelivery.com',
    // baseUrl: 'http://localhost:3000',
    projectName: "Home Hunt Delivery",
    googleMapAPIKey: "AIzaSyCX2y23lkwti4hjdH-OGJ2yrzTitQ6r_vY",
    mapZoom: 15,
    defaultLatLng: {
        lat: 41.492224,
        lng: -81.785381
    }
}
export default config;